<template>
  <div>
    <k-form-design @save="handleSave"></k-form-design>
    <formPreview ref="formPreview">
      <formPrivewContent :data="jsonData"></formPrivewContent>
    </formPreview>
  </div>
</template>

<script>
  import formPreview from './components/formPreview.vue'
  import formPrivewContent from './components/formPrivewContent.vue'
  import { jsonData } from './config'
  export default {
    components: {
      formPreview,
      formPrivewContent
    },
    data() {
      return {
        jsonData
      }
    },
    methods: {
      handleSave(evt) {
        console.log(evt)
        this.jsonData = JSON.parse(evt)
        this.$refs.formPreview.open()
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
